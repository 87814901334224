@import '../../../styles/customMediaQueries.css';


.aboutPageWrapper {
  padding: 60px 0;
  background: #ffffff;
}

@media (max-width: 768px) {
  .aboutPageWrapper {
    padding: 20px 0;
  }
}

.ourMissionSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  padding: 0 30px;
  max-width: 1400px; /* Match the width of the .offerSection */
  margin-left: auto; /* Center the section */
  margin-right: auto; /* Center the section */
}

@media (max-width: 768px) {
  .ourMissionSection {
    flex-direction: column;
    margin-bottom: 0px;
    text-align: center;
    padding: 0 20px; /* Adjust padding to match .offerSection on small screens */
  }
}

/* Adjust the .ourMissionTextBox and .ourMissionImgBox if needed */
.ourMissionTextBox, 
.ourMissionImgBox {
  flex-basis: 50%; /* Set to take up half of the .ourMissionSection */
  /* If you want the text and image side by side, remove 'flex: 1' 
     and use 'flex-basis' to assign space within the flex container */
  text-align: left;
}

@media (max-width: 768px) {
  .ourMissionTextBox, 
  .ourMissionImgBox {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }
}
/* Base styling for larger screens */
.missionTextWrapper {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 3vw; /* Use viewport width for dynamic sizing */
  padding-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .missionTextWrapper {
    padding-left: 4vw; /* Increase padding slightly on smaller desktops/large tablets */
    text-align: center;
  }
}

.textWrap {
  width: 100%;
}

.iconMission {
  height: 76px;
  width: 76px;
}

.companyText {
  color: #66788a;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
}

.greenText {
  color: var(--marketplaceColor) /* This is an example of a dark green color */
}


.headingMission {
  color: #313143;
  font-size: 42px;
  line-height: 1.15em;
  font-weight: 900;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .headingMission {
    font-size: 30px;
    margin-bottom: 0px;
  }
}



.headingJoinUs {
  color: #ffffff;
  font-size: 42px;
  line-height: 1.15em;
  font-weight: 900;
  margin-bottom: 30px;
  @media (max-width: 768px)  {
    font-size: 30px;
    margin-bottom: 8px;
  }
}

.missionDescription {
  color: #66788a;
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 768px)  {
    display: none;
    }
}

.ourMissionImgBox {
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  width: 50%; /* Consistent with text box width */

  @media (max-width: 768px)  {
    width: 100%; /* Full width on smaller screens */
    display: none;
  }
}


.subHeading {
  color: #ffffff;
  font-size: 24px;
  line-height: 1.15em;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.joinUsContent {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.missionImg {
  width: 70%; /* Image takes full width of its container */
  height: auto; /* Keep image aspect ratio */
  object-fit: cover; /* Cover the area without stretching */
}


.offerSection {
  min-height: 360px;
  padding: 40px 0 40px 0; /* Top and bottom padding only, removed left and right padding */
  background-color: #f4f4f4;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 768px) {
  .offerSection {
    padding: 20px 0 20px 0;
    /* Adjust styles as needed for smaller screens */
    /* Note: 'flex-direction: column;' is removed as it's not applicable here */
  }
}

.offerInner {
  width: 100%; /* Full width of the section */
  max-width: 1400px; /* Or as per your design requirements */
  margin: 0 auto;
  display: flex;
  justify-content: space-around; /* This will spread the columns evenly */
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 10px; /* Add horizontal padding here if needed */
}

@media (max-width: 768px) {
  .offerInner {
    flex-direction: column;
    text-align: center;
  }
}

.offerHeading {
  text-align: center;
  font-size: 48px;
  line-height: 1.3;
  margin-bottom: 5px; /* Reduced bottom margin to decrease the gap */
  color: #313143;
  font-weight: 900;
  width: 100%;
}


.columnHeading {
  text-align: left; /* Align the column headings to the left */
  font-size: 24px;
  font-weight: bold;
  color: var(--marketplaceColor);
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .columnHeading {
    text-align: center; /* Center align on smaller screens */
    margin-bottom: 4px; /* Adjust bottom margin for smaller screens */
  }
}


.columnGroupInner,
.columnGroup, .withOutArrowGroup {
  justify-content: space-around;
  align-items: flex-start;
  column-gap: 20px;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

@media (max-width: 768px) {
  .columnGroupInner,
  .columnGroup, .withOutArrowGroup {
    gap: 5px; /* if you want to keep column-gap for smaller screens */
    column-gap: 5px;
    justify-content: none;
    flex-direction: column;
    align-items: center; /* Added to align items in the center on smaller screens */
    /* Additional styles for mobile view */
  }
}


.withOutArrowGroup {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  column-gap: 20px;
  flex-direction: column;
  gap: 25px;

  @media (--viewportMedium) {
    column-gap: 0px;
    flex-direction: row;
  }
}

.columnImage {
  width: 50%; /* Set width to 50% of the column */
  height: auto; /* Set height to auto to maintain aspect ratio */
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  display: block; /* Ensure the image is displayed as a block element */
}

.columnImage1 {
  width: 100%; /* Set width to 100% of the column */
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  display: block; /* Ensure the image is displayed as a block element */
}

.columnImage1 {
  width: 85%; /* Set width to 100% of the column */
  height: auto; /* Set height to auto to maintain aspect ratio */
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  display: block; /* Ensure the image is displayed as a block element */
}
@media ((max-width: 768px) ) {
  .columnImage1 {
    width: 40%; /* Full width in smaller viewports */
    margin-left: auto; /* Center the image */
    margin-right: auto; /* Center the image */
  }
}

.mainPage {
  margin-left: 20%;
  margin-right: 20%;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0 12px;
  }
}

.inputField {
  display: block;
  width: 35%; /* Full width to match the button */
  height: 80%;
  padding: 10px 10px; /* Padding to match the button's padding */
  font-size: 20px; /* Font size to match the button */
  background-color: #ffffff; /* Background color to match the button */
  color: var(--marketplaceColorDark); /* Text color to match the button's border color */
  border: 1px solid var(--marketplaceColorDark); /* Border color to match the button */
  border-radius: 4px; /* Border-radius to match the button */
  margin-bottom: 20px; /* Margin bottom to give space between the fields */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Box shadow to match the button */
  transition: all 0.3s ease; /* Transition for interactive states like hover and focus */
  @media (max-width: 768px) {
    width: 100%; /* Full width to match the button */
    font-size: 12px; /* Font size to match the button */
    flex-direction: column;
  }
}

.inputField:focus {
  outline: none; /* Removes the default focus outline */
  border-color: darken(var(--marketplaceColorDark), 10%); /* Slightly darkens the border color on focus for visibility */
  box-shadow: 0 0 0 2px rgba(102, 120, 138, 0.5); /* Adds a glow effect similar to the button's hover state */
}

.inputField1 {
  display: block;
  width: 50%; /* Full width to match the button */
  height: 80%;
  padding: 10px 10px; /* Padding to match the button's padding */
  font-size: 20px; /* Font size to match the button */
  background-color: #ffffff; /* Background color to match the button */
  color: var(--marketplaceColorDark); /* Text color to match the button's border color */
  border: 1px solid var(--marketplaceColorDark); /* Border color to match the button */
  border-radius: 4px; /* Border-radius to match the button */
  margin-bottom: 20px; /* Margin bottom to give space between the fields */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Box shadow to match the button */
  transition: all 0.3s ease; /* Transition for interactive states like hover and focus */
}

.inputField1:focus {
  outline: none; /* Removes the default focus outline */
  border-color: darken(var(--marketplaceColorDark), 10%); /* Slightly darkens the border color on focus for visibility */
  box-shadow: 0 0 0 2px rgba(102, 120, 138, 0.5); /* Adds a glow effect similar to the button's hover state */
}

/* Style for the text color of input values */
.joinUsSection .inputField:-webkit-autofill,
.joinUsSection .inputField:-webkit-autofill:hover, 
.joinUsSection .inputField:-webkit-autofill:focus, 
.joinUsSection .inputField:-webkit-autofill:active {
  -webkit-text-fill-color: #FFFFFF; /* Ensuring text fill is white */
  transition: background-color 5000s ease-in-out 0s; /* Transition for autofill background */
}


.arrowBoxWrapper .columnGroupInner,
.arrowBoxWrapper .columnGroup {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  column-gap: 20px;
  align-items: center;
  gap: 50px;

  @media (max-width: 768px)  {
    column-gap: 20px;
    flex-direction: column;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    flex-wrap: wrap;
    text-align: center;
  }
}



.whiteSection {
  min-height: 360px;
  padding: 0 3vw; /* Example of using vw for dynamic padding */
  background-color: #ffffff;
}
.arrowRight {
  align-self: center;
  width: 40px;
  display: none;
  
  @media (--viewportMedium) {
    width: 70px;
    display: block;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    display: none;
  }
}

.bulletPoint {
  text-align: left; /* Align the bullet points to the left */
  font-size: 16px;
  color: #66788a;
  margin-bottom: 10px;
  list-style-type: none;
}
.columnsContainer {
  display: flex;
  justify-content: space-between; /* Maximizes space between columns */
  width: 100%;
  padding: 0 60px; /* Adjust if needed */
}

@media (max-width: 768px) {
  .columnsContainer {
    padding: 0; /* Remove horizontal padding */
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center-align the children */
    text-align: center;
    font-size: 12px
  }
}
.column1 {
  flex: 0 0 calc((100% / 3) - 10px); /* Adjust the subtracted value to reduce column width */
  flex: 20%;
  text-align: center;
}
@media (max-width: 768px) {
  .column1 {
    padding: 0; /* Remove horizontal padding */
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center-align the children */
    text-align: center;
    font-size: 12px
  }
}

.column {
  flex: 0 0 calc((100% / 3) - 10px); /* Adjust the subtracted value to reduce column width */
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  .column {
    text-align: center;
    align-items: center; /* Center-align items for mobile views */
    /* Add any additional styles for mobile layout here */
  }
}
/* Increase margins for the first and last columns to push them to the edges */
.column:first-child {
  margin-right: 20px; /* Increase as needed */
}

.column:last-child {
  margin-left: 20px; /* Increase as needed */
}

.columnText {
  font-size: 16px;
  color: var(--marketplaceColor);
  margin-bottom: 8px;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .columnText {
    text-align: center; /* Center-align text on smaller screens */
    font-size: 14px; /* Reduce font size on smaller screens */
    align-self: center;
  }
}

.submissionMessage {
  color: #ffffff; /* White text */
  font-size: 16px; /* Appropriate font size */
  margin-left: 0px; /* Spacing from the submit button */
  display: inline-block; /* Inline display next to the button */
}

/* Responsive styles (if needed) */
@media (max-width: 768px) {
  .submissionMessage {
    display: block; /* Optional: Display on a new line on smaller screens */
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
}

.graySection {
  min-height: 360px;
  padding: 20px 0;
  background-color: #f4f4f4;
}

.offerBtn {
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 20px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  font-weight: 500;
  margin-top: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  margin: 40px auto 0;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

.joinUsSection {
  min-height: 400px;
  padding: 40px;
  background-color: #f4f4f4;
  background-image: url('./images2/background1.1.png'); /* Updated path */
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 768px)  {
    padding: 20px 12px;
    flex-direction: column;
  }
}




.joinUsSection:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.joinUsInner {
  position: relative;
  max-width: 1400px; /* This width should be the same as the section below it */
  margin: 0 auto; /* This will center the inner content */
  display: flex;
  flex-direction: column; /* Stack the children vertically */
  align-items: center; /* Center-align the children */
  width:100%;
}

.joinUsBox {
  width: 100%; /* Full width to use all available space */
  max-width: 1400px; /* Same max-width to align with .joinUsInner */
  margin: 0 auto; /* Center the box */
  align-items: center; /* This will center the child elements */
  padding: 0 80px 15px; /* You can adjust the padding to match the .offerInner section if needed */
}
.joinUsBox {
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}

.joinUsText {
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  width: 100%;
  max-width: 920px;
  margin: 0 0 40px;

  @media (max-width: 768px)  {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}


.joinUsBtn {
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 4px;
  color: var(--marketplaceColorDark); /* Dark text color */
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  font-weight: bold;
  border: 1px solid var(--marketplaceColorDark); /* Adjusted for consistency */
  cursor: pointer;
  display: flex;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark); /* Dark background on hover */
    color: #ffffff; /* White text on hover */
    border: 1px solid #ffffff; /* Adjusted for consistency */
    font-weight: bold;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    display: center;
  }
}

.questionSection {
  min-height: 360px;
  padding: 60px;
  background-color: #f4f4f4;

  @media (max-width: 768px)  {
    padding: 60px 12px;
  }
}

.questionInner {
  width: 100%;
  margin: 0 auto;
}

.questionHeading {
  margin-bottom: 48px;
  font-size: 40px;
  line-height: 48px;
  color: #2d4053;
  letter-spacing: -.5px;
  text-align: center;
  font-weight: 900;
  padding: 36px 0 0;
  position: relative;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}

.questionHeading:before {
  content: '';
  position: absolute;
  background: var(--marketplaceColor);
  border: none;
  border-radius: 8px;
  height: 4px;
  width: 48px;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.accordionBox {
  width: 100%;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0 16px 32px 0 rgba(0,0,0,.05);
}


.accordionBox button {
  width: 100%;
  text-align: left;
  border: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 64px 0 0;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.accordionBox button h3 {
  padding: 24px 64px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  color: #3d4d5c;
  margin: 0;

  @media (max-width: 768px)  {
    padding: 24px 12px;
  }
}

/* Style your form grid to reduce the width of each grid item by 20% */
.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* two columns of equal width */
  grid-gap: 20px; /* Spacing between grid items */
  margin-bottom: 20px; /* Spacing below the grid for the submit button */
}

/* Adjust the grid item to be smaller by setting a max-width */
.gridItem {
  max-width: 80%; /* Set the max width to 80% of what it would normally be */
  /* If your grid items are in a flex container, ensure they don't stretch too far */
  flex: 0 0 auto;
}

/* Adding a class for form labels */
.formLabel {
  display: block; /* Ensures it takes up the full width as a block element */
  font-size: 16px; /* Choose an appropriate font size */
  color: #ffffff; /* Assuming white text color based on your theme */
  margin-bottom: 0px; /* Space between the label and the input below it */
  margin-top: 35px; /* Space above the label, if needed */
  @media (max-width: 768px) {
    margin-top: 10px; /* Space above the label, if needed */
    font-size: 12px
  }
}

.accordionBox button[aria-expanded="true"] .plusToggle span {
  transform: rotate(90deg);
}

.accordionBox button[aria-expanded="true"] .plusToggle span:last-child {
  left: 50%;
  right: 50%;
}

.plusToggle {
  color: #3d4d5c;
  width: 36px;
  height: 36px;
  border: 0;
  font-size: 1.5em;
  position: relative;
}

.plusToggle span {
  position: absolute;
  transition: 300ms;
  background: #3d4d5c;
  border-radius: 2px;
}

/* Create the "+" shape by positioning the spans absolutely */
.plusToggle span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}

.plusToggle span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}

.accordionItem {

}

.accordionBodyBox:not(:last-child) {
  border-bottom: 1px solid rgb(216, 216, 216);
}

.accordionBodyBox {
  
}

.columnTitle {
  font-size: 40px;
  line-height: 48px;
  color: #2d4053;
  letter-spacing: -.5px;
  text-align: center;
  font-weight: 900;
  padding: 0;
  margin: 0 0 10px;
  padding: 0 0 30px;
  margin: 0 0 0 0;
}
@media (max-width: 768px) {
  .columnTitle {
    font-size: 30px;
    line-height: 34px;
    margin: 0; /* Adjusted to set all margins to 0 */
    text-align: center; /* Maintains center alignment */
  }
}



.withExampleArrowGroup {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;

  @media (--viewportMedium) {
    gap: 60px;
  }
}

.accordionBody {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  color: #3d4d5c;
  padding: 0px 64px 14px;

  @media (max-width: 768px) {
    padding: 0px 12px 14px;
  }
}

.faqSupSection {
  padding: 60px 0 0;
  /*background-color: #f4f4f4;*/
}

.faqSupInner {
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;

  @media (max-width: 768px)  {
    flex-direction: column;
    padding: 0 12px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 0 24px;
  }
}

.linkBox {
  width: calc(50% - 10px);
  padding: 60px 12px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px)  {
    width: 100%;
  }
}

.boxHeading {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.linkBtn {
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 20px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  font-weight: 500;
  border: none;
  cursor: pointer;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

