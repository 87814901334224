@import '../../../styles/customMediaQueries.css';

.columnTitle {
  text-align: center;
  color: var(--marketplaceColor);
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .columnTitle {
    font-size: x-large;
    background-size: cover; /* Ensure background covers the area */
  }
}

.cardsRow {
  padding: 0 0 72px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100% / 2 - 20px), 1fr)); /* 2 columns on smaller screens */
  gap: 20px;
  width: 100%;
  
  @media (--viewportMedium) {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 5 - 20px), 1fr)); /* 5 columns on larger screens */
  }
}

.thumbnailCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 1px 6px 0 #20212447;
  border-radius: 4px;
  background: linear-gradient(#ffffff, #f1f1f1);
  padding: 10px;
  flex: 1; /* Stretch to fill the container */
}

.thumbnailInnerBox {
  width: 100%;
  margin-bottom: 10px; /* Space between image and text */
}

.thumbnailInnerBox img {
  width: 100%; /* Image should fill the container */
  height: auto; /* Adjust height to maintain aspect ratio */
  object-fit: cover;
  border-radius: 4px; /* Match the border radius from the box */
}

.thumbnailInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-grow: 1; /* Allow this box to grow to fill space */
}

.thumbnailInfo .thumbHeading {
  font-size: 20px;
  line-height: 1.2;
  color: var(--marketplaceColor);
  font-weight: 700;
  margin-bottom: 10px; /* Space between heading and text */
}

.thumbnailInfo .thumbText {
  font-size: 18px;
  line-height: 1.2;
  color: var(--colorGrey700);
  text-align: center; /* Center the text */

  @media (max-width: 768px) {
    font-size: 12px; /* Smaller font size for mobile screens */
  }
}
