@import '../../../styles/customMediaQueries.css';

.columnTitle {
  text-align: center;
  color: var(--marketplaceColor);
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .columnTitle {
    font-size: x-large;
    background-size: cover; /* Ensure background covers the area */
  }
}

.cardsRow {
  padding: 0 0 72px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.advantageWrapper {
  padding: 0 0 72px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.thumbnailCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Adjust the size as necessary */
  margin: 0 auto; /* Center the cards */
  text-align: center; /* Center text inside the card */
}

.thumbnailInnerBox {
  width: 100; /* Match the width of the card */
  height: 100; /* Ensure this is the same as the width for a perfect circle */
  border-radius: 50%; /* Make the box round */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.thumbnailInnerBox img {
  object-fit: cover; /* Cover the area of the box without stretching the image */
  border-radius: 50%; /* Maintain circular shape */
  width: 70%; /* Fill the container */
  height: auto; /* Fill the container */
  border: 4px solid var(--marketplaceColor); /* Green border */
}

.thumbnailInfo {
  width: 100%; /* Ensure the text container takes up all horizontal space */
  padding: 10px;
}

.thumbnailInfo .thumbHeading {
  font-size: 20px;
  line-height: 1.2;
  margin: 10px 0; /* Adjust spacing as needed */
  color: var(--marketplaceColor);
  font-weight: 700;
  /* Media query for screens less than 768 pixels wide */
  @media (max-width: 768px) {
    font-size: 18px; /* Smaller font size for mobile screens */
  }
}


.thumbnailInfo .thumbText {
  font-size: 18px; /* Default font size for larger screens */
  line-height: 1.2;
  color: var(--colorGrey700);

  /* Media query for screens less than 768 pixels wide */
  @media (max-width: 768px) {
    font-size: 14px; /* Smaller font size for mobile screens */
  }
}