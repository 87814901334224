@import '../../../styles/customMediaQueries.css';

.columnTitle {
  text-align: center;
}
@media (max-width: 768px) {
  .columnTitle {
    font-size: x-large;
    background-size: cover; /* Ensure background covers the area */
  }
}

.columnSubTitle {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 30px;
}

.coachingWrapper {
  text-align: center;
}