@import '../../../styles/customMediaQueries.css';

.columnTitle {
  text-align: center;
  color: var(--marketplaceColor);
}
.questionSection {
  min-height: 360px;
  padding: 60px;
  background-color: #fafafa;

  @media (max-width: 767px) {
    padding: 60px 12px;
  }
}

.questionInner {
  width: 100%;
  margin: 0 auto;
}

.questionHeading {
  margin-bottom: 48px;
  font-size: 40px;
  line-height: 48px;
  color: #2d4053;
  letter-spacing: -.5px;
  text-align: center;
  font-weight: 900;
  padding: 20px 0 0;
  position: relative;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}

.questionHeading:before {
  content: '';
  position: absolute;
  background: var(--marketplaceColor);
  border: none;
  border-radius: 8px;
  height: 4px;
  width: 48px;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.accordionBox {
  width: 100%;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0 16px 32px 0 rgba(0,0,0,.05);
}


.accordionBox button {
  width: 100%;
  text-align: left;
  border: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 64px 0 0;

  @media (max-width: 767px) {
    padding: 0;
  }
}

.accordionBox button h3 {
  padding: 24px 64px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  color: #3d4d5c;
  margin: 0;

  @media (max-width: 767px) {
    padding: 24px 12px;
  }
}

.accordionBox button[aria-expanded="true"] .plusToggle span {
  transform: rotate(90deg);
}

.accordionBox button[aria-expanded="true"] .plusToggle span:last-child {
  left: 50%;
  right: 50%;
}

.plusToggle {
  color: #3d4d5c;
  width: 36px;
  height: 36px;
  border: 0;
  font-size: 1.5em;
  position: relative;
}

.plusToggle span {
  position: absolute;
  transition: 300ms;
  background: #3d4d5c;
  border-radius: 2px;
}

/* Create the "+" shape by positioning the spans absolutely */
.plusToggle span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}

.plusToggle span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}

.accordionItem {

}

.accordionBodyBox:not(:last-child) {
  border-bottom: 1px solid rgb(216, 216, 216);
}

.accordionBodyBox {
  
}

.accordionBody {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  color: #3d4d5c;
  padding: 0px 64px 14px;

  @media (max-width: 767px) {
    padding: 0px 12px 14px;
  }
}

.faqSupSection {
  padding: 60px 0 0;
  /*background-color: #f4f4f4;*/
}

.faqSupInner {
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 12px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 0 24px;
  }
}