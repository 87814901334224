@import '../../../styles/customMediaQueries.css';

.columnTitle {
  text-align: center;
  color: var(--marketplaceColor);
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .columnTitle {
    font-size: x-large;
    background-size: cover; /* Ensure background covers the area */
  }
}

.featuredCoaches {
  padding: 0 0 72px; /* Top Right Bottom Left */
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 8px; /* Spacing between grid items */
  width: 100%; /* Full width of the container */

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* This will create as many 250px columns as will fit in the container */
  }

  @media (min-width: 769px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* This will create a layout with a minimum column size of 250px and as many columns as will fit */
  }
}

.ListingCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This will center the content vertically */
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  width: 100%; /* Takes the full width of the parent */
  height: auto; /* Maintains the aspect ratio */
  border-radius: 50%; /* Ensures the image itself is also circular */
  object-fit: cover; /* Ensures the image covers the area properly */
}

.imgBox {
  border-radius: 50%; /* Ensures the border is circular */
  border: 4px solid var(--marketplaceColor); /* Adjust the border color and thickness as needed */
  overflow: hidden; /* Ensures the image doesn't overflow the circular border */
  display: flex; /* This will help to center the content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 200px; /* Adjust according to your requirement */
  height: 200px; /* Adjust according to your requirement */
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
  text-align: center;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  font-size: 20px;
  padding-bottom: 10px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}