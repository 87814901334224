@import '../../../styles/customMediaQueries.css';

.columnTitle {
  display: flex; /* Use flexbox to center the content */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  color: var(--marketplaceColor);
  padding: 0;
  line-height: 1.2;
  font-size: 24px;
  margin: 0 0 24px;

  &:hover {
    text-decoration: underline;
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    text-align: left;
    font-size: 32px;
    margin: 0 0 32px;
  }
}

.dashboardWrapper {
  width: 100%;
}

.dashboardSection {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.asideTabPanel {
  width: 100%;
  border-bottom: 1px solid var(--colorGrey100);
  
  @media (--viewportLarge) {
    width: 280px;
    border: none;
    border-right: 1px solid var(--colorGrey100);
  }
}

.tabLinkWrapper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: auto;
  gap: 10px;

  @media (--viewportLarge) {
    flex-direction: column;
    gap: 0;
    overflow: initial;
  } 
}

.tabLink {
  font-weight: 600;
  color: var(--colorGrey700);
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  background: linear-gradient(to right, #ffffff, #12442914);
  border: none;
  padding: 6px 12px;
  gap: 12px;
  text-wrap: nowrap;
  font-size: 16px;
  height: 42px;
  
  &:not(last-child) {
    border: none;
    @media (--viewportLarge) {
      border-bottom: 2px solid var(--marketplaceColor);
    }
  }
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
    background-color: var(--hoverBackgroundColor); /* A slight color change */
    transform: translateX(5px); /* Slight right movement */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Drop shadow for depth */
  }
  @media (--viewportLarge) {
    gap: 12px;
    padding: 32px 16px;
    font-size: 14px;
    text-wrap: initial;
    height: auto;
  }
}

.tabLink img {
  height: 24px;

  @media (--viewportLarge) {
    height: 32px;
  }
}

.tabLinkIcon {
  /* ... */
  width: 24px; /* Set a fixed width for the icons */
  min-width: 24px; /* Ensures icon box doesn't shrink */
  text-align: center; /* Centers the icon in the fixed width */
  /* ... */
}
@media (max-width: 768px) {
  .tabLinkWrapper {
    display: flex;
    gap: 8px; /* Increase gap to avoid elements touching each other */
  }

  .tabLink {
    box-shadow: none; /* Remove any shadow that might cause visual overlap */
    padding: 10px; /* Adjust padding to ensure a larger tap target */
    margin: 0; /* Reset margin to ensure consistent spacing */
    border-radius: 1px; /* Optional: rounded corners for a modern look */
    background: var(--colorWhite); /* Use a solid background color */
    font-size: 14px; /* Increase font size for better readability on mobile */
    gap: 8px; /* Adjust gap between text and icon */
    height: auto; /* Adjust height based on content */
    border: 4px solid var(--colorGrey700); /* Optional: add a subtle border */
    border-color: var(--marketplaceColor);
  }

  .tabLink:not(:last-child) {
    margin-bottom: 8px; /* Add space between tab links */
  }

  .tabLinkIcon {
    width: 20px; /* Adjust icon size for mobile */
    min-width: 20px; /* Ensure icon does not shrink */
  }
}

.mainPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 20px 12px;
  background-color: var(--colorGrey50);

  @media (--viewportMedium) {
    padding: 20px 24px;
  }
  @media (--viewportLarge) {
    padding: 20px 40px;
  }
} 

.mainPanelWrapper {
  width: 100%;
}

.userRow {
  display: flex;
  margin: 0 0 32px;
  flex-direction: column;
  gap: 10px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 24px;
  }
}

.userSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 220px;
  max-width: 100%;

  @media (--viewportMedium) {
    max-width: 200px;
  }
}

.profileImgBox {
  height: 150px;
  width: 150px;
  border-radius: 50%; /* Perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 12px; 
  border: 6px solid var(--marketplaceColor); /* Highlight avatar with accent color */
}

.profileImgBox .avatar {
  height: 100%;
  width: 100%;
}

.profileImgBox .avatar span {
  font-size: 18px;
}

.userTitle {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.3;
  margin: 0 0 6px;

  &:hover {
    text-decoration: underline;
    color: var(--marketplaceColor);
  }

}

.userName {
  font-size: 18px;
  line-height: 1.3;

  &:hover {
    text-decoration: underline;
    color: var(--marketplaceColor);
  }

}

.userDetails {
  flex: 1;
}

/*
.dashboardContentRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.dashboardContentBox {
  width: calc(50% - 10px);
}

*/

.dashboardContentRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */
  gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .dashboardContentRow {
    grid-template-columns: 1fr; /* One column on small screens */
  }
}

.dashboardContentBox {
  background-color: var(--dashboard-card-background-color);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05); /* Softer shadow for more depth */
  padding: 20px;
  margin-bottom: 20px;
  border: none; /* Removing the border for a cleaner look */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.dashboardContentBox:hover {
  box-shadow: 0 6px 15px rgba(0,0,0,0.1); /* Larger shadow on hover for a 'lifted' effect */
  transform: translateY(-5px); /* Slight upward movement */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}
:root {
  --dashboard-text-color: #4A4A4A;
  --dashboard-background-color: #F5F5F5;
  --dashboard-card-background-color: #FFFFFF;
  --dashboard-primary-color: #306060; /* Dark greenish-blue for a sophisticated look */
  --dashboard-accent-color: #88C0D0; /* Soft blue for accents */
}
.boxTitle {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 800;
  color: var(--marketplaceColor);
  line-height: 1.3;
  margin: 0 0 12px;
  width: 100%;
  height: 100%;
  &:hover {
    text-decoration: underline;
    color: var(--marketplaceColor);
  }
}

.userOtherDetails {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.detailBox {
  flex: 1;
  box-shadow: 0 1px 6px 0 #20212447;
  padding: 10px;
  border-radius: 4px;
  background: linear-gradient(#ffffff, #12442914);
  min-height: 200px;
}






