@import '../../styles/customMediaQueries.css';

.mainPage {
  margin-left: 20%;
  margin-right: 20%;
}
@media (max-width: 768px) {
  .mainPage {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.topHero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("assets/chessvia_landing_page120823.png");
  background-size: cover;
  background-position: top;
  padding: 120px 100px 120px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .topHero {
    padding: 30px 15px 10px; /* Reduced padding for smaller screens */
    background-size: cover; /* Ensure background covers the area */
  }
}

.typewriterContainer {
  height: 100%; 
  display: flex; 
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 40px; 
  margin-left: 16px;
  font-size: 20px;  /* Set the desired font size, 2em for larger text */
  color: #f5f5f5;
  font-weight: 400;
  line-height: 1.3em;
  display: block;

}
/* Adjust font size for smaller screens */
@media (max-width: 768px) {
  .typewriterContainer {
    font-size: 16px; /* Smaller font size for mobile */
    height: 80px; 
    margin-bottom: 0px;
    font-weight: 500;
  }
}
/*
.typewriterContainer .Typewriter__wrapper {
  font-size: 4em !important;
  color: #ffffff !important;
  font-weight: 300 !important;
  line-height: 1.3em !important;
  display: block !important;
}

.typewriterContainer .Typewriter__cursor {
  color: #3F3D56;  Cursor color
}
*/

.button {
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 20px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
    
  }
}

.signUpbutton {
  composes: button;
  margin-right: 40px;
  margin-left: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    background-color: #b69356;
    color: white;
    border-color: var(--marketplaceColor);
    
  }
}

.button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}


.howChessviaWorksbutton {
  composes: button;
  color: var(--marketplaceColor);
  background-color: white;
  margin-left: 0px;
  margin-right: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    background-color: var(--marketplaceColor);
    color: white;
    border-color: var(--marketplaceColor);
    /*
    All possible background colors to try
    background-color: #e0f2f1;
    background-color: #b8e0d2;
    background-color: #d8e4dc;
    background-color: #ecf5db;
    background-color: #e8f5e9;
    */
  }
}

.topHeroText {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topHeroTitle {
  color: var(--colorGrey50);
  font-weight: 600;
  margin-bottom: 0;
}

.topHeroSecondary {
  color: var(--colorGrey100);
  font-weight: 300;
  text-align: left;
  font-size: 2em;
  position: relative;
  white-space: normal;
}

/* Use media queries to adjust max-width for different screen sizes */
@media (max-width: 768px) {
  .topHeroSecondary {
    max-width: 85%; /* Allows some space on smaller screens */
    font-size: 1.2em; /* Slightly smaller font size on smaller screens */
  }
}

/* 
.typewriterEffect::after {
  content: '|';
  position: absolute;
  right: 0;
  animation: blinkCursor 0.7s steps(40) infinite;
}

@keyframes blinkCursor {
  from { border-right-color: rgba(255, 255, 255, 0.75); }
  to { border-right-color: transparent; }
}
*/

/* Responsive Design */
@media (max-width: 1024px) {
  .topHeroSecondary {
    max-width: 100%; /* Full width on smaller screens */
    text-align: left;
  }
}

.columnTitle {
  text-align: center;
  color: var(--marketplaceColor)
}
@media (max-width: 768px) {
  .columnTitle {
    font-size: x-large;
    background-size: cover; /* Ensure background covers the area */
  }
}

.columnSubTitle {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 30px;
}

.columnGroupInner,
.columnGroup {
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
  padding: 10px 0px 40px;
  flex-direction: column;
  align-items: stretch;
  gap: 25px;

  @media (--viewportMedium) {
    column-gap: 20px;
    flex-direction: row;
  }
}

.columnGroupInnerCustom.columnGroupInner,
.columnGroup {
  align-items: stretch;
}

.column {
  text-align: center;
  flex: 33%;
}

.columnGroupInnerCustom.columnGroupInner .column,
.columnGroup .column {
  box-shadow: 0 1px 6px 0 #20212447;
  padding: 10px;
  height: auto;
  border-radius: 4px;
  flex: 1;
  /*background: linear-gradient(#ffffff, #f1f1f1);*/
  /*background: linear-gradient(#ffffff, #12442914);*/
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.columnImg {
  width: 100%;
  height: auto;
  @media (--viewportMedium) {
    width: 65%;
  }
  @media (max-width: 768px) {
    width: 40%;
  }
}

.arrowRight {
  align-self: center;
  width: 40px;
  display: none;
  @media (--viewportMedium) {
    width: 70px;
    display: block;
  }
}

.coachingWrapper {
  text-align: center;
}

.callToActionSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; /* Ensure children fill the section vertically */

  padding: 0; /* Adjusted top and bottom padding */

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.callToActionTextBox {
  flex: 0 0 50%;
  max-width: 40%;
  padding: 20px;
}

.callToActionImgBox {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.actionImg {
  max-width: 100%;
  height: auto;
}

@media (--viewportMedium) {
  .callToActionSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .callToActionTextBox,
  .callToActionImgBox {
    flex: none;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 20px;
  }
}

/* Ensure this media query is at the bottom of your CSS file to override other styles */
@media (max-width: 768px) {
  /* This is the container that wraps your buttons */
  .topHeroButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Remove any left or right margin that might prevent stacking */
    margin-left: 0;
    margin-right: 0;
  }

  /* Target the buttons within the container for full width */
  .topHeroButtonsContainer .button {
    /* Set the buttons to full width of their container */
    width: 100%;
    /* Remove any margin between the buttons that could prevent them from stacking */
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* If your buttons have specific classes, ensure these are also targeted */
  .topHeroButtonsContainer .signUpbutton,
  .topHeroButtonsContainer .howChessviaWorksbutton {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}



