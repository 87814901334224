@import '../../../styles/customMediaQueries.css';

.columnTitle {
  text-align: center;
  padding: 0;
  line-height: 1.2;
  font-size: 24px;
  margin: 0 0 24px;

  @media (--viewportMedium) {
    text-align: left;
    font-size: 32px;
    margin: 0 0 32px;
  }
}

.dashboardWrapper {
  width: 100%;
}

.dashboardSection {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.asideTabPanel {
  width: 100%;
  border-bottom: 1px solid var(--colorGrey100);
  
  @media (--viewportLarge) {
    width: 280px;
    border: none;
    border-right: 1px solid var(--colorGrey100);
  }
}

.tabLinkWrapper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: auto;
  gap: 10px;

  @media (--viewportLarge) {
    flex-direction: column;
    gap: 0;
    overflow: initial;
  } 
}

.tabLink {
  font-weight: 600;
  color: var(--colorGrey700);
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(to right, #ffffff, #12442914);
  border: none;
  padding: 6px 12px;
  gap: 5px;
  text-wrap: nowrap;
  font-size: 12px;
  height: 42px;
  
  &:not(last-child) {
    border: none;
    @media (--viewportLarge) {
      border-bottom: 1px solid var(--colorGrey100); 
    }
  }
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
  @media (--viewportLarge) {
    gap: 12px;
    padding: 8px 16px;
    font-size: 14px;
    text-wrap: initial;
    height: auto;
  }
}

.tabLink img {
  height: 24px;

  @media (--viewportLarge) {
    height: 32px;
  }
}

.mainPanel {
  flex: 1;
  padding: 20px 12px;

  @media (--viewportMedium) {
    padding: 20px 24px;
  }
  @media (--viewportLarge) {
    padding: 20px 40px;
  }
} 

.mainPanelWrapper {
  width: 100%;
}

.coachCards {
  padding: 0 0 72px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}
